import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import { options } from "../../../shared/shared";

export default function NavBar() {
  const [dropDown, setDropDown] = useState(null);
  let navRef = useRef(null);
  const [navBarName, setNavBarName] = useState("navBar");

  const navBarOptions = () => {
    return (
      <div className="links" ref={navRef}>
        {options.slice(1).map((option, index) => {
          return (
            <div
              className="nav-link-wrapper"
              key={index}
              onMouseOver={() => {
                setDropDown(option.route);
              }}
              onMouseLeave={() => {
                setDropDown(false);
              }}
              onClick={() => {
                setDropDown(null);
              }}
            >
              <NavLink
                to={option.route}
                exact="true"
                className={`nav-link ${option.title}`}
              >
                {option.title}
              </NavLink>
              <div className="botDiv">
                {items_list(option.route, option.item_list)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const items_list = (routes, items) => {
    if (items) {
      return (
        <ul
          className={getDropDownMenu(routes, items)}
          onMouseOver={() => {
            setDropDown(true);
          }}
        >
          {items.map((item, index) => {
            return (
              <NavLink key={index} to={item.route} exact="true">
                {item.title}
              </NavLink>
            );
          })}
        </ul>
      );
    }
  };
  const getDropDownMenu = (setClass, items) => {
    const isOpen =
      dropDown === setClass ? "drop_down_menu" : "drop_down_menu close";
    const sizeClass = items.length > 6 ? "" : " small";

    return isOpen + sizeClass;
  };
  return <div className={`${navBarName}`}>{navBarOptions()}</div>;
}
