import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import news2 from "../../all__news4.json";
import { useGlobalLocation } from "../../shared/LocationContext";
import { calendarData } from "../../shared/shared";
import SimpleButton from "../../UI/Buttons/SimpleButton";
import "./Calendar.scss";
import CalendarTable from "./CalendarTable";

const { idsMonths, manualDateEaster, easterDays, calendarYears, monthSerb } =
  calendarData;
export default function Calendar(props) {
  let { slug, id } = useParams();
  const { location, currentDate } = useGlobalLocation();
  const [isYear, setIsYear] = useState(() => {
    if (slug) {
      return slug;
    } else {
      return currentDate.getFullYear();
    }
  });

  const easter = isYear - 2020;
  const [isMonth, setIsMonth] = useState(() => {
    if (id === undefined) {
      return currentDate.getMonth();
    } else {
      return monthSerb.indexOf(id);
    }
  });
  const [holidays, setHolidays] = useState(filterHolidays);
  const [dropDownYear, setDropDownYear] = useState(false);
  function filterHolidays() {
    if (slug && id) {
      setIsYear(slug);
      setIsMonth(monthSerb.indexOf(id));
    }
    let setHol = news2.slice(idsMonths[isMonth][0], idsMonths[isMonth][1]);
    let setHolTest = setHol.map((item, index) => {
      let date1 = new Date(isYear, isMonth, index + 1);
      item.date = date1;
      let date2 = new Date(`${isYear}-${manualDateEaster[easter]}`);
      if (date2.toDateString() === date1.toDateString()) {
        item.title = easterDays[easterDays.length - 1];
      }
      return item;
    });
    return setHolTest;
  }
  useEffect(() => {
    setHolidays(filterHolidays);
  }, [id, slug, isMonth, isYear]);

  const navigate = useNavigate();

  const changeMonth = (val, secParam) => {
    if (id === undefined) {
      navigate(`/crkveni-kalendar/${secParam || isYear}/${tableTitle(val)}`);
    } else {
      if (isMonth + val === 12) {
        navigate(`../crkveni-kalendar/${secParam || +isYear + 1}/januar`);
      } else if (isMonth === 0 && val === -1) {
        navigate(`../crkveni-kalendar/${secParam || +isYear - 1}/decembar`);
      } else {
        navigate(
          `../crkveni-kalendar/${secParam || isYear}/${tableTitle(val)}`
        );
      }
    }
    if (secParam) {
      setIsYear(secParam);
    } else {
      if (isMonth === 11 && val === 1) {
        setIsMonth(0);
        setIsYear(+isYear + 1);
      } else if (isMonth === 0 && val === -1) {
        setIsMonth(11);
        setIsYear(+isYear - 1);
      } else {
        setIsMonth(isMonth + val);
      }
    }
  };
  const tableTitle = (x) => {
    if (id === undefined) {
      if (isMonth + x === 12) {
        return `JANUAR (${+isYear + 1})`;
      } else if (isMonth + x === -1) {
        return `${monthSerb[11]} (${+isYear + x})`;
      } else {
        return monthSerb[isMonth + x];
      }
    } else {
      if (isMonth + x === 12) {
        let e = +isYear;
        return `${monthSerb[0]} (${+isYear + x})`;
      } else if (isMonth + x === -1) {
        return `${monthSerb[11]} (${+isYear + x})`;
      } else {
        return monthSerb[monthSerb.indexOf(id) + x];
      }
    }
  };
  const setCloseClass = () => {
    if (location.pathname === "/") {
      return " close";
    } else {
      return "";
    }
  };
  //change the calendar year
  const items_list = (items) => {
    return (
      <ul className={getDropDownMenu()}>
        {items.map((item, index) => {
          return (
            <SimpleButton
              key={index}
              clicked={() => {
                setDropDownYear(false);
                changeMonth(0, item.title);
              }}
            >
              {item.title}
            </SimpleButton>
          );
        })}
      </ul>
    );
  };
  const getDropDownMenu = () => {
    return dropDownYear ? "drop_down_menu" : "drop_down_menu close";
  };

  return (
    <div className="calendar">
      {/* ---- Gornje ranfle kalendara ---- */}
      <div className="first">
        <h1>Crkveni pravoslavni kalendar</h1>
        <div
          className={`yearBox${setCloseClass()}`}
          onClick={() => {
            setDropDownYear(true);
          }}
          onMouseLeave={() => {
            setDropDownYear(false);
          }}
        >
          <b>{isYear}</b>
          <i class="fa-solid fa-square-caret-down"></i>
          <div className="botDiv">{items_list(calendarYears[0].item_list)}</div>
        </div>
      </div>
      <div className={`calendar-month${setCloseClass()}`}>
        <div>
          <SimpleButton clicked={() => changeMonth(-1)}>
            <i className="fa-solid fa-backward"></i>
            {tableTitle(-1)}
          </SimpleButton>
        </div>
        <div className="month-center"></div>
        <div>
          <SimpleButton clicked={() => changeMonth(1)}>
            {tableTitle(1)}
            <i className="fa-solid fa-forward"></i>
          </SimpleButton>
        </div>
      </div>
      {/* ---- END Gornje ranfle kalendara ---- */}

      {/* ---- Kalendar ---- */}
      <CalendarTable
        props={props}
        tabaleTitle={tableTitle(0)}
        isYear={isYear}
        holidays={holidays}
      />

      {/* ---- END Kalendar ---- */}

      {/* ---- Donja ranfla kalendara ---- */}
      <div className="calendar-month">
        {[[-1, "fa-backward"], [0], [1, "fa-forward"]].map((item, index) => {
          return (
            <div key={index}>
              <SimpleButton clicked={() => changeMonth(item[0])}>
                <i className={`fa-solid ${item[1]}`}></i>
                <span>{tableTitle(item[0])}</span>
              </SimpleButton>
            </div>
          );
        })}
      </div>
      {/* ---- END Donja ranfla kalendara ---- */}
    </div>
  );
}
