// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import ScrollToTop from "./UI/ScrollToTop/ScrollToTop";

// ReactDOM.render(
//   <React.StrictMode>
//     <ScrollToTop />
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
