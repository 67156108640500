import React, { lazy, Suspense } from "react";
import { useGlobalLocation } from "../../shared/LocationContext";
import "./Ikone.scss";
// import AdManagerSlot from "../../components/AdvModule/AdManagerSlot";
const AdManagerSlotLazy = lazy(() =>
  import("../../components/AdvModule/AdManagerSlot")
);
export default function Ikone() {
  const location = useGlobalLocation();
  return (
    <div className="ikone">
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy
            adUnitPath={location.pathname}
            slotNumber={"div-gpt-ad-1723658374440-0"}
          />
        </Suspense>
      </div>
      <div className="ikone-wrapper">
        <div className="ikone-text">
          <p>
            Ikonopis (ili ikonopisanje) je posebna slikarska tehnika koju neguje
            hrišćanska crkva (naročito Pravoslavna).
          </p>
          <p>
            U Pravoslavnoj Crkvi naročito je raširen i razvijen kult ikona, i
            mada ikone spadaju u kult i drugih konfesija, pravoslavlje je
            najfinije i najdetaljnije razvilo ikonopis tako da sva pravila i
            načini ikonopisa, dole napisana, karakteristična su za Pravoslavnu
            Crkvu. U drugim konfesijama koje poštuju ikonu ova pravila ne moraju
            biti poštovana (primer je Rimokatolička crkva u kojoj nije detaljno
            preciziran i dogmatski određen način slikanja ikona). U Pravoslavnoj
            Crkvi postoje određeni kanoni (pravila) ikonopisanja kojih se
            pridržava svaki ikonopisac.
          </p>
          <p>
            Pravila nisu zvanično propisana već se prenose generacijama sa
            majstora na učenika. Osnovno nepisano pravilo je da se ikona radi po
            obrascu (predložku) neke starije ikone (koja je opšte prihvećeni
            obrazac).
          </p>
          <p>
            Slikanje ikona naravno ne mora uvek biti po predlošku,već po opisu
            iz npr."Ohridskog prologa"ili i samog SVETOG PISMA,ali to naravno
            zavisi od veštine, znanja i obrazovanja samog umetnika.
          </p>
        </div>
        <div className="banner-wrapper xl_sticky">
          <Suspense fallback={<div></div>}>
            <AdManagerSlotLazy
              adUnitPath={location.pathname}
              slotNumber={"div-gpt-ad-1723682121612-0"}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
