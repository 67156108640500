import React from "react";
import { useParams } from "react-router-dom";
import { useGlobalLocation } from "../../shared/LocationContext";
import "./Zadusnice.scss";

export default function Zadusnice() {
  let { slug, id } = useParams();
  const { currentDate } = useGlobalLocation();
  const currentDate2 = currentDate.getYear() + 1900;
  console.log("CD", slug);
  const setYear = () => {
    return slug || currentDate2;
  };
  return (
    <div className="zadusnice">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th colSpan="2">Zadušnice u {setYear()}. godini</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>9. mart</td>
            <td>Zimske</td>
          </tr>
          <tr>
            <td>22. jun</td>
            <td>Letnje</td>
          </tr>
          <tr>
            <td>5. oktobar</td>
            <td>Miholjske</td>
          </tr>
          <tr>
            <td>2. novembar</td>
            <td>Mitrovske (jesenje)</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
