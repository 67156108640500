import React, { Component } from "react";
import "./ArticleWidget.scss";
import news from "../../all__news.json";

export default function ArticleWidget(props) {
  // state = {
  //   loaded: false,
  //   error: false,
  //   fallBackSrc: "/img/manastir.jpg",
  // };
  const urlTitle = (item) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let d = item
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      });
    return d.join("").split(" ").join("-").replace(regex, "-");
  };

  const setPreUrlTitle = (item) => {
    let e = urlTitle(item.title);
    return (
      "https://agroweb.rs/vesti/" + urlTitle(item.category) + "/" + e + "/"
    );
  };

  const onImageError = () => {
    this.setState({ error: true });
  };
  const articleIndex = props.data;
  // console.log("Data n", articleIndex);
  // const redUrl = props.redUrl;
  // const index = this.props.index;
  const classes = props.classes;
  // let imgSrc = !this.state.error
  //   ? news[articleIndex].pics[0]
  //   : this.state.fallBackSrc;

  return (
    <div>
      <a
        target="_blank"
        className={classes}
        href={setPreUrlTitle(articleIndex)}
      >
        <div className="test11">
          <img src={articleIndex.pics[0]} alt="" />
          <div className="test12">
            <h2>
              {articleIndex.title_2 ? articleIndex.title_2 : articleIndex.title}
            </h2>
            <h3 className="test112">
              {articleIndex.category} <span>| {articleIndex.time}</span>
            </h3>
          </div>
        </div>
      </a>
    </div>
  );
}
