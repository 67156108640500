import React, { Component, lazy, Suspense } from "react";
import "./Manastiri.scss";
import spisakManastira from "../../allData";
import Pagination from "../../UI/Pagination/Pagination";
import news from "../../all__news.json";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
// import AdvModule from "../../components/AdvModule/AdvModule";
// import ContentExBox from "../../components/ContentEx/ContenExBox";
// import Helmet from "react-helmet";
// import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const SmallBoxLazy = lazy(() => import("./Tools/SmallBox"));
const WidgetLazy = lazy(() =>
  import("../../components/AdvModule/AdManagerSlot")
);
export default class Manastiri extends Component {
  state = {
    objects: spisakManastira,
    search1: "",
    search2: "",

    //state for pagination
    pageNum: 1,
    operationPerPage: 13,
    elemNum: [0, 12],
    //end
  };

  updateSearch1 = (event) => {
    this.setState({ search1: event.target.value.substr(0, 20) });
  };
  updateSearch2 = (event) => {
    this.setState({ search2: event.target.value.substr(0, 20) });
  };

  removeObjekat = (id) => {
    const { objects } = this.state;
    const filter = objects.filter((object) => object.id !== id);
    this.setState({
      objects: filter,
    });
  };
  // functions for Pagination ==========================
  componentDidMount = () => {
    this.setPageNumber();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.objects !== prevState.objects) {
      this.setPageNumber();
    }
  };
  setPageNumber = () => {
    this.setState({
      numberOfPages: Math.ceil(
        this.state.objects.length / (this.state.operationPerPage - 1)
      ),
    });
  };
  setPaginationPage = (page) => {
    // this.scrollTop.scrollIntoView({ behavior: "smooth" });
    this.setState({
      elemNum: [
        (page - 1) * 12,
        (page - 1) * 12 + this.state.operationPerPage - 1,
      ],
      pageNum: page,
    });
    return this.state.objects.slice(
      this.state.elemNum[0],
      this.state.elemNum[1]
    );
  };
  //funkcija kojom se odredjuje da li ce se prikazati pagination
  //trenutno nije aktivna
  showPagination = () => {
    return this.state.group.length < this.state.operationPerPage ? "none" : "";
  };
  //====================== end =========================
  getNews = (num, classes) => {
    return num.map((n, index) => {
      return <ArticalBox n={n} key={index} classes={classes} data={news[n]} />;
    });
  };
  urlTitle = (title) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!"];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  };
  render() {
    // let e = this.newMethod();
    let { filtriraniObjekti1, filtriraniObjekti2 } = this.newMethod();
    let filters12 = filtriraniObjekti1.filter((x) =>
      filtriraniObjekti2.includes(x)
    );
    const products = filters12.slice(
      this.state.elemNum[0],
      this.state.elemNum[1]
    );
    return (
      <div className="objectsList">
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
        <Suspense fallback={<div>Loading...</div>}>
          <div className="objectsList__objects">
            <div className="objectsList__objects-left">
              <h1>Manastiri u Srbiji</h1>
              <section className="objectsList__objects__obj">
                <div
                  ref={(el) => {
                    this.scrollTop = el;
                  }}
                  style={{ position: "absolute", top: "0" }}
                ></div>
                <div className="obj-wrapper">
                  {products.map((objekat, index) => {
                    return (
                      <SmallBoxLazy
                        key={index}
                        n={objekat}
                        removeObjekat={this.removeObjekat}
                      />
                    );
                  })}
                </div>
                <Pagination
                  numberOfPages={this.state.numberOfPages}
                  pageNum={this.state.pageNum}
                  clicked={this.setPaginationPage}
                />
              </section>
            </div>
            {/* <div className="objectsList__objects-right">
              <h1>Priče iz manastira</h1>
              <section className="home__box__4 header">
                <div className="home__box__4-layout">
                  {this.getNews([4, 5, 6], "test1 box4 small")}
                  <AdvModule classes={"adClass xl2"} size={"xl"} />
                </div>
              </section>
            </div> */}

            <div className="kalendar-right">
              {/* <AdvModule classes={"adClass"} size={"xl"} /> */}
            </div>
          </div>
        </Suspense>
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
        {/* <ContentExBox /> */}
        <Suspense fallback={<div></div>}>
          <WidgetLazy />
        </Suspense>
      </div>
    );
  }

  newMethod() {
    // console.log(this.state.objects);
    let filtriraniObjekti1 = this.state.objects.filter((objekat) => {
      return (
        objekat.type
          .toLowerCase()
          .substring(0, this.state.search1.length)
          .indexOf(this.state.search1.toLowerCase()) !== -1
      );
    });
    // console.log("Flter", filtriraniObjekti1);
    let filtriraniObjekti2 = this.state.objects.filter((objekat) => {
      return (
        objekat.title
          .toLowerCase()
          .substring(0, this.state.search2.length)
          .indexOf(this.state.search2.toLowerCase()) !== -1
      );
    });
    return { filtriraniObjekti1, filtriraniObjekti2 };
    // return "1";
  }
}
