import React, { Suspense, lazy } from "react";
import { useGlobalLocation } from "../../shared/LocationContext";
import TimeFormat from "../TimeFormat/TimeFormat";
import { inCalendarArr } from "../../shared/sharedAds";
import { calendarData } from "../../shared/shared";
const { daysIsPost, daysIsNotPost, redDaysId } = calendarData;
const AdManagerSlotLazy = lazy(() => import("../AdvModule/AdManagerSlot"));

export default function CalendarTable(props) {
  console.log("Props calTable", props);

  const { location, currentDate } = useGlobalLocation();
  const rowClasses = (index) => {
    return redDaysId.includes(index) ? "redRow" : "";
  };
  const todayClass = (x) => {
    if (
      x.getDate() === currentDate.getDate() &&
      x.getMonth() === currentDate.getMonth()
    ) {
      return " today";
    } else {
      return "";
    }
  };
  const setPostDays = (dateInfo) => {
    let setDateFromDateInfo = new Date(dateInfo);

    //start - Bozic i Bozicni post
    let bozicniPostStart = new Date(props.isYear, 10, 28); //pocetak posta korigovati!!!
    let bozicniPostEnd = new Date(props.isYear, 0, 6);

    let mrsniDaniPosleBozica = new Date(props.isYear, 0, 17);
    let notPost = daysIsNotPost.map((item) => {
      return new Date(props.isYear, item[0], item[1]).setHours(0, 0, 0, 0);
    });
    let isPost = daysIsPost.map((item) => {
      return new Date(props.isYear, item[0], item[1]).setHours(0, 0, 0, 0);
    });
    let setDateDay = setDateFromDateInfo.getDay();
    if (
      setDateFromDateInfo >= bozicniPostStart ||
      setDateFromDateInfo <= bozicniPostEnd
    ) {
      return "post";
    } else if (setDateFromDateInfo <= mrsniDaniPosleBozica) {
      return "";
    } else if (setDateDay === 3 || setDateDay === 5) {
      if (!notPost.includes(setDateFromDateInfo.setHours(0, 0, 0, 0))) {
        return "post";
      }
    } else if (isPost.includes(setDateFromDateInfo.setHours(0, 0, 0, 0))) {
      return "post";
    } else {
      return "";
    }
  };
  function setMonth(short) {
    if (short) {
      //short month on home page
      const setShortCal = () => {
        if (currentDate.getDate() < 7) {
          return props.holidays.slice(0, currentDate.getDate() + short);
        } else {
          return props.holidays.slice(
            currentDate.getDate() - short,
            currentDate.getDate() + short
          );
        }
      };
      return setShortCal();
    } else {
      return props.holidays;
    }
  }
  let inTextNumber = 0;
  const nedelje = [];
  return (
    <table className="calendar-table" cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          {["Dani", "Novi", "Stari"].map((item, index) => {
            return (
              <th key={index}>
                <span>{item}</span>
              </th>
            );
          })}
          <th>
            <h2>
              {props.tabaleTitle} {props.isYear}
            </h2>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {setMonth(props.props.shortCal).map((item, index) => {
          let z = new Date(item.date);
          let zz = z.getDay();
          const tdClasses = ["onlyDay", "noDay", "before"];
          if (zz === 1) {
            nedelje.push(inTextNumber);
            inTextNumber++;
            return (
              <>
                <tr className="opisNedelje">
                  <td colSpan={5}>
                    {[1, 2, 3, 4, 5].includes(inTextNumber) && (
                      <div className="banner-wrapper calendar">
                        <Suspense fallback={<div></div>}>
                          <AdManagerSlotLazy
                            adUnitPath={location.pathname}
                            slotNumber={inCalendarArr[inTextNumber - 1]}
                          />
                        </Suspense>
                      </div>
                    )}
                  </td>
                </tr>
                <tr
                  key={index}
                  className={
                    rowClasses(item.id) + todayClass(new Date(item.date))
                  }
                >
                  {tdClasses.map((x, index) => {
                    return (
                      <td key={index}>
                        <TimeFormat timePost={item.date} classes={x} />
                      </td>
                    );
                  })}
                  <td>
                    <div className="test">
                      <h3>{item.title}</h3>
                    </div>
                  </td>
                  <td>{setPostDays(item.date)}</td>
                </tr>
              </>
            );
          } else {
            return (
              <tr
                key={index}
                className={
                  rowClasses(item.id) + todayClass(new Date(item.date))
                }
              >
                {tdClasses.map((x, index) => {
                  return (
                    <td key={index}>
                      <TimeFormat timePost={item.date} classes={x} />
                    </td>
                  );
                })}
                <td>
                  <div className="test">
                    <h3>{item.title}</h3>
                  </div>
                </td>
                <td>{setPostDays(item.date)}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
}
