export const calendarData = {
  idsMonths: [
    [0, 31],
    [31, 59],
    [59, 90],
    [90, 120],
    [120, 151],
    [151, 181],
    [181, 212],
    [212, 243],
    [243, 273],
    [273, 304],
    [304, 334],
    [334, 365],
  ],
  daysIsNotPost: [
    [0, 7],
    // [0, 12],
    // [0, 14],
    [0, 19],
    [1, 16],
    [1, 18],
  ],
  daysIsPost: [
    [0, 18],
    [8, 11],
    [8, 27],
  ],
  redDaysId: [
    7, 8, 9, 14, 19, 20, 27, 46, 153, 154, 163, 164, 165, 188, 193, 214, 231,
    240, 254, 264, 270, 300, 304, 312, 325, 338, 353,
  ],
  monthSerb: [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar",
  ],
  manualDateEaster: [
    "4-19",
    "5-2",
    "4-24",
    "4-16",
    "5-5",
    "4-20",
    "4-12",
    "5-2",
    "4-16",
    "4-8",
    "4-28",
    "4-13",
    "5-2",
  ],
  easterDays: [
    "Veliki četvrtak (Veliko bdenije)",
    "Veliki petak",
    "Velika subota",
    "V a s k r s – Vaskrsenje Gospoda Isusa Hrista",
  ],
  calendarYears: [
    {
      item_list: [
        { title: 2022, route: "/crkveni-kalendar/2022" },
        { title: 2023, route: "/crkveni-kalendar/2023" },
        { title: 2024, route: "/crkveni-kalendar/2024" },
        { title: 2025, route: "/crkveni-kalendar/2025" },
        { title: 2026, route: "/crkveni-kalendar/2026" },
        { title: 2027, route: "/crkveni-kalendar/2027" },
        { title: 2028, route: "/crkveni-kalendar/2028" },
      ],
    },
  ],
};

// export const monthSerb = [
//   "januar",
//   "februar",
//   "mart",
//   "april",
//   "maj",
//   "jun",
//   "jul",
//   "avgust",
//   "septembar",
//   "oktobar",
//   "novembar",
//   "decembar",
// ];

export const spisakNedelja = [
  "Седмица прва – Светла",
  "Седмица друга – Мироносица",
  "Седмица трећа – Раслабљеног",
  "Седмица четврта – Самарјанке",
  "Седмица пета – Слепога",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица Педесетнице",
];
export const details = [
  { title: "Ime i prezime", name: "name" },
  { title: "Kontakt telefon", name: "tel" },
  { title: "Vaša poruka", name: "text" },
];
export const tretmanColors = [
  { tretman: "manastir", color: "#940922" },
  { tretman: "crkva", color: "#b29411" },
  // { tretman: "nega lica", color: "#F7A072" },
  // { tretman: "nega tela", color: "#55C1FF" },
  { tretman: "ostalo", color: "#5F1A37" },
];
export const options = [
  //0
  {
    route: "/",
    title: "Ikone.rs",
    social: {
      title: "Ikone.rs",
      lead: "Ikone.rs - Ikone, crkveni kalendar, manastiri...",
      pics: "/img/test3.jpg",
    },
  },
  //1
  { route: "/manastiri", title: "Manastiri" },
  //2
  {
    route: "/ikone",
    title: "Ikone",
  },
  //3
  {
    route: "/crkveni-kalendar",
    title: "Crkveni kalendar",
    social: {
      title: "Crkveni kalendar",
      lead: "Crkveni pravoslavni kalendar | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar",
      pics: "/img/test3.jpg",
    },
    social2: {
      title: "Crkveni kalendar",
      lead: " | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar ",
      pics: "/img/test3.jpg",
    },
    item_list: [
      {
        title: "Januar 2024.",
        route: "/crkveni-kalendar/2024/januar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za januar 2024.",
          lead: "Crkveni kalendar za januar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Februar 2024.",
        route: "/crkveni-kalendar/2024/februar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za februar 2024.",
          lead: "Crkveni kalendar za februar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Mart 2024.",
        route: "/crkveni-kalendar/2024/mart",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za mart 2024.",
          lead: "Crkveni kalendar za mart 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "April 2024.",
        route: "/crkveni-kalendar/2024/april",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za april 2024.",
          lead: "Crkveni kalendar za april 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Maj 2024.",
        route: "/crkveni-kalendar/2024/maj",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za maj 2024.",
          lead: "Crkveni kalendar za maj 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Jun 2024.",
        route: "/crkveni-kalendar/2024/jun",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za jun 2024.",
          lead: "Crkveni kalendar za jun 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Jul 2024.",
        route: "/crkveni-kalendar/2024/jul",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za jul 2024.",
          lead: "Crkveni kalendar za jul 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Avgust 2024.",
        route: "/crkveni-kalendar/2024/avgust",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za avgust 2024.",
          lead: "Crkveni kalendar za avgust 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Septembar 2024.",
        route: "/crkveni-kalendar/2024/septembar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za septembar 2024.",
          lead: "Crkveni kalendar za septembar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Oktobar 2024.",
        route: "/crkveni-kalendar/2024/oktobar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za oktobar 2024.",
          lead: "Crkveni kalendar za oktobar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Novembar 2024.",
        route: "/crkveni-kalendar/2024/novembar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za novembar 2024.",
          lead: "Crkveni kalendar za novembar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Decembar 2024.",
        route: "/crkveni-kalendar/2024/decembar",
        icon: "/img/icons/other.png",
        social: {
          title: "Crkveni kalendar za decembar 2024.",
          lead: "Crkveni kalendar za decembar 2024. godinu - Saznajte sve o pravoslavnim praznicima, postima i svecima. Pratite važne datume i obeležavanja tokom cele godine.",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      // {
      //   title: "Seoske priče",
      //   route: "/zivot-na-selu/seoske-price/",
      //   icon: "/img/icons/other.png",
      // },
      // {
      //   title: "Zdrav život",
      //   route: "/zivot-na-selu/zdrav-zivot/",
      //   icon: "/img/icons/other.png",
      // },
      // {
      //   title: "Tradicionalni recepti",
      //   route: "/zivot-na-selu/tradicionalni-recepti/",
      //   icon: "/img/icons/other.png",
      //   social: {
      //     title: "Tradicionalni recepti",
      //     lead: "Najnovije vesti - Tradicionalni recepti",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
    ],
  },

  //4
  {
    route: "/ostalo/meseceve-mene",
    title: "Mesečeve mene",
    social: {
      title: "Mesečeve mene",
      lead: "Mesečeve mene i lunarne faze u 2023. godini. MESEČEV KALENDAR. Precizan kalendar faza meseca za kompletnu godinu. Prikaz mesečevih mena za svaki mesec...",
      pics: ["/img/article_img/moon.jpg", "Pexels"],
    },
  },
  //2
  {
    route: "/zadusnice",
    title: "Zadušnice",
  },
  // { route: "/molitve", title: "Molitve" },
  // { route: "/slave", title: "Slave" },
  // { route: "/ostalo", title: "Ostalo" },
];

export const footer = [
  "O nama",
  "Marketing",
  "Pravila korišćenja",
  "Politika privatnosti",
];
