import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { options } from "../../shared/shared";

export default function HeadHelmet(props) {
  const loc = useLocation();
  let pathPart = loc.pathname.split("/");
  let lastPathPart = pathPart[pathPart.length - 1];
  const tagNews = props.allNews.filter((item) => {
    // return urlTitle2(item.tags[0]) === lastPathPart;
  });
  // console.log("PAth part", pathPart[1] === "crkveni-kalendar", pathPart);
  const [post, setPost] = useState(() => setArticleState());

  useEffect(() => {
    setPost(setArticleState());
  }, [loc.pathname]);

  function setArticleState() {
    if (lastPathPart === "") {
      let post = options[0].social;
      return post;
    } else if (pathPart[1] === "crkveni-kalendar" && pathPart.length > 2) {
      let post = options[3].social2;
      let testSocTag = {
        pics: post.pics,
        source: "Ikone.rs",
        title: `Crkveni pravoslavni kalendar - ${lastPathPart.toUpperCase()} ${
          pathPart[2]
        } `,
        // time2: tagNews[tagNews.length - 1].time2,
        // modified: tagNews[0].time2,
        lead: `Crkveni pravoslavni kalendar - ${lastPathPart.toUpperCase()} ${
          pathPart[2]
        } ${post.lead} ${pathPart[2]}`,
      };
      return testSocTag;
    } else if (pathPart[1] === "crkveni-kalendar" && pathPart.length === 2) {
      let post = options[3].social;
      return post;
    } else if (pathPart[2] === "meseceve-mene") {
      let post = options[4].social;
      return post;
    } else {
      let post = options[0].social;
      return post;
    }
  }
  const setTitle = (postTitle) => {
    return postTitle.title_2 ? postTitle.title_2 : postTitle.title;
  };
  // const setSource = (item) => {
  //   return Array.isArray(item) ? `${item[1]}` : "agroweb.rs";
  // };
  // const setModifiedTime = (item) => {
  //   if (item) {
  //     return <meta property="article:modified_time" content={post.modified} />;
  //   }
  // };
  // function urlTitle2(title) {
  //   const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
  //   const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
  //   let regex = /--/gi;
  //   let url_title = title
  //     .toLowerCase()
  //     .split("")
  //     .map((x) => {
  //       return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
  //     })
  //     .join("")
  //     .split(" ")
  //     .join("-")
  //     .replace(regex, "-");
  //   return url_title;
  // }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{setTitle(post)}</title>
      <link rel="canonical" href={"https://ikone.rs" + loc.pathname} />
      <meta name="description" content={post.lead} />
      {/* Opengraph - Facebook */}
      <meta property="og:locale" content="sr-RS" />
      <meta property="og:type" content="article" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/ikone.portal"
      />
      <meta property="og:title" content={setTitle(post)} />
      {/* <meta name="author" content={setSource(post.source)} /> */}
      <meta property="og:site_name" content="Ikone.rs" />
      <meta property="og:image" content={post.pics[0]} />
      {/* <meta property="og:image:secure_url" content={post.pics[0]} /> */}
      <meta property="og:image:width" content="640" />
      <meta property="og:image:height" content="480" />
      <meta property="og:image:alt" content={setTitle(post)} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:url" content={"https://ikone.rs" + loc.pathname} />
      {/* <meta property="article:published_time" content={post.time2} /> */}
      {/* {setModifiedTime(post.modified)} */}
      <meta property="og:description" content={post.lead} />
      {/* End Opengraph */}
    </Helmet>
  );
}
