import React, { lazy, Suspense } from "react";
import "./Home.scss";
import news from "../../all__news.json";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import Calendar from "../../components/Calendar/Calendar";
import { useGlobalLocation } from "../../shared/LocationContext";
import { calendarData } from "../../shared/shared";
import Zadusnice from "../../components/Zadusnice/Zadusnice";
import PosniKalendar from "../../components/PosniKalendar/PosniKalendar";

const WidgetLazy = lazy(() => import("../../UI/Widget/Widget"));
const AdManagerSlotLazy = lazy(() =>
  import("../../components/AdvModule/AdManagerSlot")
);

export default function Home() {
  // window.scrollTo(0, 0);
  const location = useGlobalLocation();
  const { monthSerb } = calendarData;

  const getNews = (num, classes) => {
    return num.map((n, index) => {
      return <ArticalBox n={n} key={index} classes={classes} data={news[n]} />;
    });
  };

  return (
    <div className="home__1">
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy
            adUnitPath={location.pathname}
            slotNumber={"div-gpt-ad-1723658374440-0"}
          />
        </Suspense>
      </div>
      {/* <section className="home__box__4 header">
        <div className="home__box__4-layout">
          {getNews([3, 4, 5], "test1 box4")}
        </div>
      </section> */}
      <section className="home__box__4">
        <div className="home__box__4-left">
          <Calendar shortCal={6} soc={false} />
          <div className="banner-wrapper">
            <Suspense fallback={<div></div>}>
              <AdManagerSlotLazy
                adUnitPath={location.pathname}
                slotNumber={"div-gpt-ad-1725620172811-0"}
              />
            </Suspense>
          </div>
          <table className="calendar-allMonths">
            <thead>
              <tr>
                {/* <th>Kalendar 2024</th> */}
                {/* <th>2</th> */}
                {/* <th>3</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                {monthSerb.slice(0, 4).map((item, index) => {
                  return (
                    <td key={index}>
                      <a
                        title={`Crkveni kalendar - ${item} 2024 | Pravoslavni kalendar`}
                        href={`https://ikone.rs/crkveni-kalendar/2024/${item}`}
                      >
                        {item}
                      </a>
                    </td>
                  );
                })}
              </tr>
              <tr>
                {monthSerb.slice(4, 8).map((item, index) => {
                  return (
                    <td key={index}>
                      <a
                        title={`Crkveni kalendar - ${item} 2024 | Pravoslavni kalendar`}
                        href={`https://ikone.rs/crkveni-kalendar/2024/${item}`}
                      >
                        {item}
                      </a>
                    </td>
                  );
                })}
              </tr>
              <tr>
                {monthSerb.slice(8, 12).map((item, index) => {
                  return (
                    <td key={index}>
                      <a
                        title={`Crkveni kalendar - ${item} 2024 | Pravoslavni kalendar`}
                        href={`https://ikone.rs/crkveni-kalendar/2024/${item}`}
                      >
                        {item}
                      </a>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <Zadusnice />
        </div>
        <div className="home__box__4-right">
          {/* <PosniKalendar /> */}
          <div className="banner-wrapper xl_sticky">
            <Suspense fallback={<div></div>}>
              <AdManagerSlotLazy
                adUnitPath={location.pathname}
                slotNumber={"div-gpt-ad-1723682121612-0"}
              />
            </Suspense>
          </div>
        </div>
      </section>
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy
            adUnitPath={location.pathname}
            slotNumber={"div-gpt-ad-1724367417806-0"}
          />
        </Suspense>
      </div>
      {/* <section className="home__box__4 sec">
        <h3>Pročitajte još...</h3>
        <div className="home__box__4-layout">
          {getNews([6, 7], "test1 mainBox")}
          {getNews([4, 9, 10, 11], "test1 box4")}
        </div>
      </section> */}
      {/* <div className="home__box__4 contEx"> */}
      <Suspense fallback={<div></div>}>
        <WidgetLazy />
      </Suspense>
    </div>
  );
}
