import { React, useState, useEffect } from "react";
import "../SinglPost/SinglePost.scss";
import { useParams } from "react-router-dom";
import data from "../../all__news.json";
// import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import BodyText from "../../components/BodyText/BodyText.js";

export default function Idemo() {
  window.scrollTo(0, 0);
  const [id, setID] = useState(useParams());
  const [Post, setPost] = useState(null);
  const [Filtered, setFiltered] = useState(null);
  let filtered1 = data.filter((x) => {
    return urlTitle2(x.title) !== id.id;
  });
  let post = data.filter((post) => {
    return urlTitle2(post.title) === id.id;
  });
  // console.log("Post1", id.id);
  // console.log("Filtered", Filtered);
  useEffect(() => {
    setPost(post);
    setFiltered(filtered1);
  }, [useParams()]);
  // console.log("Post2", Post);
  // console.log("Filtered", Filtered);

  return (
    <div className="singlePost">
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      <div className="content">
        {mainPost(Post)}
        <aside className="newsAside">
          <div className="newsAside-box">
            <h4>Najnovije vesti</h4>
            {newsAside(Post, Filtered)}
          </div>
          {/* <AdvModule classes={"adClass"} size={"xl"} /> */}
          <div className="newsAside-box">
            <h4>Najčitanije vesti</h4>
            {newsAside(Post, Filtered)}
          </div>
          {/* <AdvModule classes={"adClass"} size={"xl"} /> */}
        </aside>
      </div>
    </div>
  );
}
const urlTitle2 = (title) => {
  const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!"];
  const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", ""];
  let regex = /--/gi;
  let url_title = title
    .toLowerCase()
    .split("")
    .map((x) => {
      return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
    })
    .join("")
    .split(" ")
    .join("-")
    .replace(regex, "-");
  return url_title;
};
const mainPost = (post) => {
  // console.log("Post 62", post);
  if (post === null) {
    <div>Loading...</div>;
  } else {
    return (
      <main className="mainContent">
        <span className="mainContent-cat">{post[0].category}</span>
        <h1 className="mainContent-title">{post[0].title}</h1>
        <h3 className="mainContent-lead">{post[0].lead}</h3>
        <div className="time">
          Izvor: {post[0].source}, Datum: {post[0].time}
        </div>
        <img className="mainContent-img" src={`${post[0].pics}`} alt="" />
        {/* <BodyText bodyText={this.state.post[0].body} /> */}
        <div>
          {post[0].body.map((y) => {
            return (
              <div>
                <h2 className="mainContent-subtitle">{y.subtitle}</h2>
                <p>{y.text}</p>
              </div>
            );
          })}
        </div>
        <div className="tags">
          <h4>Tagovi</h4>
          <div className="tags__arr">
            {post[0].tags.map((x, index) => {
              return <span key={index}>{x}</span>;
            })}
          </div>
        </div>
        <section className="home__box__13">
          <h4>Najnovije vesti - {post[0].category}</h4>
          <div className="home__rightBox">
            <ArticalBox n={5} classes={"test1 mainBox"} />
            <ArticalBox n={6} classes={"test1 mainBox"} />
          </div>
        </section>
        <section className="home__box__11">
          <h4>Preporučujemo</h4>
          <div className="home__rightBox">
            <ArticalBox n={4} classes="test1 box2" />
            <ArticalBox n={5} classes="test1 box2" />
            <ArticalBox n={3} classes="test1 box2" />
          </div>
        </section>
      </main>
    );
  }
};
const newsAside = (post, filtered) => {
  if (post === null) {
    return <div>Loading...</div>;
  } else {
    // console.log(filtered);
    return (
      <div>
        {filtered.slice(0, 3).map((news, index) => {
          return (
            <ArticalBox key={index} n={index + 3} classes={"test1 sideBox"} />
          );
        })}
      </div>
    );
  }
};
