import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const LocationContext = createContext();

export const useGlobalLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const location = useLocation();
  const { slug, id } = useParams();
  // console.log("Location", location.pathname);
  const pathPart = location.pathname.split("/");
  const [previousLocation, setPreviousLocation] = useState(null);
  const currentDate = new Date();
  // useEffect(() => {
  //   setPreviousLocation(window.location.hostname);
  // }, [location]);
  console.log("Slug", slug, id);
  return (
    <LocationContext.Provider
      value={{ currentDate, location, pathPart, previousLocation, slug, id }}
    >
      {children}
    </LocationContext.Provider>
  );
};
